import { useMemo } from 'react'
import _ from 'lodash'
import { createTheme, ThemeOptions } from '@mui/material/styles'
import type { Theme } from '@emotion/react'

const useMuiTheme = ({
  theme,
  options,
}: {
  theme: Theme
  options?: ThemeOptions
}) => {
  return useMemo(
    () =>
      createTheme(
        _.merge(
          {},
          {
            palette: {
              primary: {
                main: theme.primary,
              },
              secondary: {
                main: theme.secondary,
              },
            },
            components: {
              MuiDataGrid: {
                styleOverrides: {
                  root: {
                    '--unstable_DataGrid-headWeight': 600,
                    '--DataGrid-containerBackground': theme['secondary-50'],
                    border: '1px solid #DFE0E1',
                    fontFamily: 'Open Sans',
                    '& .MuiDataGrid-pinnedRows': {
                      backgroundColor: '#DFE0E1',
                    },
                  },
                  columnHeaders: {
                    backgroundColor: theme['secondary-50'],
                    fontSize: 14,
                    fontWeight: 500,
                    boxShadow: '0px -1px 0px 0px #DEE0E3 inset',
                    color: theme['secondary-700'],
                    letterSpacing: 0.17,
                    '.grouping-column-header': {
                      fontSize: 0,
                    },
                    '.MuiDataGrid-columnHeader--sorted': {
                      color: theme.primary,
                      '& .MuiDataGrid-sortIcon': {
                        color: theme.primary,
                      },
                    },
                  },
                  row: {
                    fontSize: 14,
                    fontWeight: 400,
                    letterSpacing: 0.17,
                    color: theme['secondary-900'],
                    backgroundColor: '#fff',
                    '&.tree-child-row': {
                      backgroundColor: theme['secondary-50'],
                    },
                    '&:hover': {
                      backgroundColor: theme['secondary-light-500'],
                    },
                    '&.Mui-selected': {
                      backgroundColor: theme['secondary-light-500'],
                    },
                  },
                  cell: {
                    '&.MuiDataGrid-cell:focus': {
                      outline: 'none',
                    },
                    '&.MuiDataGrid-cell:focus-within': {
                      outline: 'none',
                    },
                  },
                },
              },
            },
          },
          options
        )
      ),
    [options, theme]
  )
}

export default useMuiTheme
