// libraries
import { useMemo, useState } from 'react'
import _ from 'lodash'
import { useUpdateEffect } from 'react-use'
import { ColumnProps } from 'primereact/column'
import {
  TABLE_COLUMN_GROUP_BY_FIELD_KEY,
  TABLE_COLUMN_OLD_FIELD_KEY,
  TABLE_COLUMN_SUPPORT_GROUP_BY_KEY,
} from 'constants/common'

const DEFAULT_COLUMNS_OPTIONS = {
  headerStyle: undefined,
  bodyStyle: undefined,
  sortable: true,
  resizeable: true, // https://primereact.org/treetable/#api.Column.props.resizeable
}

// Making a copy of the default type so if we need to add something later,
// we can do it just here
export type ColumnOptions = ColumnProps & {
  key?: string
}

export type Column = Pick<
  ColumnOptions,
  | 'header'
  | 'body'
  | 'expander'
  | 'field'
  | 'hidden'
  | 'style'
  | 'sortable'
  | 'key'
  | 'bodyStyle'
  | 'sortField'
>

export type Columns = Column[]

export type ColumnWithGroupable = Column & {
  [TABLE_COLUMN_SUPPORT_GROUP_BY_KEY]?: boolean
  [TABLE_COLUMN_GROUP_BY_FIELD_KEY]?: string
  [TABLE_COLUMN_OLD_FIELD_KEY]?: string
}

export type SetVisibleColumns = (columnsData: ColumnOptions[]) => void

export type ColumnsWithGroupable = ColumnWithGroupable[]

export const getTableGroupableColumns = (
  columns: ColumnsWithGroupable
): ColumnsWithGroupable => _.filter(columns, TABLE_COLUMN_SUPPORT_GROUP_BY_KEY)

const calcVisibleColumns = ({
  initialVisibleColumns,
  columnsData,
}: {
  initialVisibleColumns?: string[]
  columnsData: ColumnsWithGroupable
}) =>
  initialVisibleColumns?.length
    ? _.filter<ColumnOptions>(
        columnsData,
        ({ field, oldField }) =>
          _.includes(initialVisibleColumns, field) ||
          _.includes(initialVisibleColumns, oldField)
      )
    : columnsData

export const useDataTableColumns = ({
  columns,
  options,
  initialVisibleColumns,
}: {
  columns?: Columns
  options?: ColumnOptions
  initialVisibleColumns?: string[]
}): {
  columns: ColumnOptions[]
  visibleColumns: ColumnOptions[]
  setVisibleColumns: SetVisibleColumns
} => {
  const columnsData = useMemo(() => {
    return _.map(columns, col =>
      _.omit(
        {
          ..._.defaults({}, options, DEFAULT_COLUMNS_OPTIONS),
          ...col,
        },
        TABLE_COLUMN_SUPPORT_GROUP_BY_KEY
      )
    )
  }, [columns, options])

  // * For now, the 'columns' prop is static (constant) so we don't need to worry about 'refreshing' the state data.
  const [visibleColumns, setVisibleColumns] = useState<ColumnOptions[]>(() =>
    // Apply the preserved state if we have it
    calcVisibleColumns({ initialVisibleColumns, columnsData })
  )

  useUpdateEffect(() => {
    setVisibleColumns(oldVisibleColumns =>
      _.isEqual(oldVisibleColumns, columnsData)
        ? oldVisibleColumns
        : columnsData
    )
  }, [columnsData])

  return {
    columns: columnsData,
    visibleColumns,
    setVisibleColumns,
  }
}
