import { useBranding } from 'hooks'
import { FC, memo } from 'react'
import scss from './index.module.scss'

type TopBarLogoProps = {
  logoUrl?: string
  className?: string
}

export const TopBarLogo: FC<TopBarLogoProps> = memo(({ className }) => {
  const {
    logo: { topBarLogo },
  } = useBranding()

  if (!topBarLogo) return null

  return (
    <img src={topBarLogo} alt='Organization Logo' className={scss.topBarLogo} />
  )
})

TopBarLogo.displayName = 'TopBarLogo'
