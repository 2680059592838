import _ from 'lodash'
import type { ApolloClient, ApolloError, DocumentNode } from '@apollo/client'
import type { PageInfo } from 'app/MissionControlMethaneSolution/types/graphql'
import { showError } from 'helpers/message'

export const defaultMutationErrorHandler = (err: ApolloError) => {
  showError(err.name, {
    description: err.message,
    optionalExtraInfo: [err.extraInfo],
  })
}

/**
 * Fetches all pages of a Relay-style GraphQL query
 * @param config Configuration for pagination
 * @returns Array of processed nodes
 */
export const fetchAllRelayDataByApolloClient = async <T>({
  client,
  query,
  variables,
  dataPath,
  first = 1000,
  processNode = (node: T) => node as T,
}: {
  client: ApolloClient<unknown>
  query: DocumentNode
  variables: object
  dataPath: string[]
  first?: number
  processNode?: (node: T) => T
}): Promise<T[]> => {
  let allNodes: T[] = []
  let pageInfo: PageInfo | undefined

  do {
    const { data } = await client.query({
      query,
      variables: {
        ...variables,
        first,
        after: pageInfo?.endCursor,
      },
    })

    const response = _.get(data, dataPath)
    const edges = response?.edges || []
    pageInfo = response?.pageInfo
    allNodes = [
      ...allNodes,
      ...edges.map((edge: { node: T }) => processNode(edge.node)),
    ]
  } while (pageInfo?.hasNextPage && pageInfo?.endCursor)

  return allNodes
}
