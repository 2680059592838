import type { DataGridPremiumProps } from '@mui/x-data-grid-premium'

export const SLIDER_EXTRA_LENGTH_REGULAR = 9
export const SLIDER_HANDLE_RADIUS_REGULAR = 9
export const SLIDER_HANDLE_DIAMETER_REGULAR = SLIDER_HANDLE_RADIUS_REGULAR * 2

export const SLIDER_EXTRA_LENGTH_LARGE = 13
export const SLIDER_HANDLE_RADIUS_LARGE = 15
export const SLIDER_HANDLE_DIAMETER_LARGE = SLIDER_HANDLE_RADIUS_LARGE * 2

export const SLIDER_RAIL_COLOR = '#E1DFDF'

export const MUI_DATAGRID_DEFAULT_GROUPING_COL_DEF: DataGridPremiumProps['groupingColDef'] =
  {
    width: 50,
    headerClassName: 'grouping-column-header',
    resizable: false,
  }

export const MUI_GRID_GROUP_HEADER_NODE_TYPE = 'group'
