import _ from 'lodash'
import { matchRoutes } from 'react-router-dom'
import { ROUTE_OPTIONAL_PARAMS_REGEX } from 'constants/common'
import type { Payload } from 'types/common'

const getSearchParamsFromPath = (routePath: string) => {
  // Matching search params /maps/:mapId?edit => ?edit
  const matchResult = routePath.match(/\?[^/:]*$/g)

  const searchParams = matchResult?.[0] || ''
  // Could match only '?' if there is an optional param in the end,
  // so checking if there is more than 1 character matched
  const hasSearchParams = searchParams.length > 1
  // If the route has search params, remove them to get a clean path
  const cleanPath = hasSearchParams
    ? routePath.replace(searchParams, '')
    : routePath

  return { cleanPath, hasSearchParams, searchParams }
}

/**
 * Generates a URL by replacing path parameters and appending query parameters.
 *
 * @param {string} routeUrl - The route template containing parameters (e.g., `/users/:id/profile`).
 * @param {Record<string, string | undefined | null>} [routeValues] - An object mapping route parameters to their values.
 * @param {Record<string, string | undefined | null>} [forceQueryParams] - Parameters that should always be included as query parameters.
 * @returns {string} - The generated URL with path and query parameters correctly applied.
 */
export const getRouteUrlWithValues = (
  routeUrl: string = '',
  routeValues?: Record<string, string | undefined | null>,
  forceQueryParams?: Record<string, string | undefined | null>
): string => {
  const { cleanPath, hasSearchParams, searchParams } =
    getSearchParamsFromPath(routeUrl)

  const validParams = _.omitBy(routeValues, _.isNil)
  const cleanQueryParams = _.omitBy(forceQueryParams, _.isNil) // Remove undefined/null values

  const pathParams = _.omit(validParams, Object.keys(cleanQueryParams || {}))
  const queryParams = cleanQueryParams
    ? _.merge(
        _.pick(validParams, Object.keys(cleanQueryParams)),
        cleanQueryParams
      )
    : {}

  let newUrl = _.reduce(
    pathParams,
    (route, value, key) =>
      route
        .replace(new RegExp(`/:${key}\\?`, 'g'), `/${value}`)
        .replace(new RegExp(`:${key}`, 'g'), value),
    cleanPath
  )

  // Remove any remaining optional params like `/:param?`
  newUrl = newUrl.replace(ROUTE_OPTIONAL_PARAMS_REGEX, '').replace(/\/+$/, '')

  // Construct query string
  const queryString = new URLSearchParams(queryParams).toString()

  return (
    newUrl +
    (queryString ? `?${queryString}` : '') +
    (hasSearchParams ? searchParams : '')
  )
}

/**
 * Just a shortcut for 'window.history.replaceState'
 * ! window.history.replaceState will bypass React Router's internal mechanisms
 * for detecting route changes, the results from React Router's hooks,
 * like `useParams`, will not return the most up-to-date results
 * * recommend to use React Router's built-in navigation methods(useNavigate).
 */

export const changeRoute = (routeUrl: string, routeValues: Payload<string>) =>
  window.history.replaceState(
    undefined,
    '',
    getRouteUrlWithValues(routeUrl, routeValues)
  )

export const getRouteUrlWithQueryParams = (
  url: string,
  queryParams?: Payload<string>
) => {
  const queryString = new URLSearchParams(queryParams).toString()
  return `${url}${queryString ? `?${queryString}` : ''}`
}

/** See https://reactrouter.com/en/main/utils/match-routes */
export const getRouteParams = (routePath: string) => {
  const [{ params }] = matchRoutes(
    [
      {
        path: routePath,
      },
    ],
    window.location
  ) || [{}]

  return params
}
