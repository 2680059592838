import { useMemo } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import _ from 'lodash'

// utils
import { siteEquipmentsQuery } from 'app/MissionControlMethaneSolution/recoilStore/siteStore'

import type { SiteEquipmentOption } from 'types/sites'
import { useFeatureFlag } from 'hooks'
import { FEATURES } from 'constants/settings'

const useSiteEquipments = ({
  assetReference,
}: {
  assetReference?: string // site: uuid, well:uuid, etc
}): {
  isLoading: boolean
  options: SiteEquipmentOption[]
} => {
  const { [FEATURES.GEMS_CONFIGURATION]: gemsConfiguration } = useFeatureFlag()

  const siteEquipmentsPropertyFilters = _.get(
    gemsConfiguration,
    'assets.siteEquipmentsPropertyFilters'
  )

  const siteEquipmentsLoadable = useRecoilValueLoadable(
    siteEquipmentsQuery({
      parentAssetReference: assetReference,
      siteEquipmentsPropertyFilters,
    })
  )
  const equipmentOptions = useMemo(
    () =>
      siteEquipmentsLoadable.state === 'hasValue'
        ? siteEquipmentsLoadable.contents
        : [],
    [siteEquipmentsLoadable.contents, siteEquipmentsLoadable.state]
  )

  return {
    isLoading: siteEquipmentsLoadable.state === 'loading',
    options: equipmentOptions,
  }
}

export default useSiteEquipments
