import keymirror from 'keymirror'

export const SUB_ASSET_TYPES = keymirror({
  equipment: null,
})

// ! This asset types should be fetched from the backend when available
export const ASSET_TYPES = keymirror({
  ...SUB_ASSET_TYPES,
  site: null,
  well: null,
  pipeline: null,
})

export type AssetTypeId = keyof typeof ASSET_TYPES

export const ASSET_TYPE_ID_ORDER = [
  ASSET_TYPES.site,
  ASSET_TYPES.equipment,
  ASSET_TYPES.well,
  ASSET_TYPES.pipeline,
]

export const NO_NAME_PLACEHOLDER = 'Unknown Name'

export const ASSET_COORDINATE_PROPERTIES = [
  'centroidLatitude',
  'centroidLongitude',
]
