import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useInterval } from 'ahooks'
import { useState } from 'react'

const CONTEXT_CHECK_INTERVAL = 500

/**
 * Polls ldClient.getContext() until there is no { anonymous: true } in the response,
 * which means the context was identified and fresh flag data based on that context was fetched.
 * */
const useLDUserContextIdentified = () => {
  const ldClient = useLDClient()
  const [isIdentified, setIsIdentified] = useState(false)

  useInterval(
    async () => {
      if (ldClient && !isIdentified) {
        const context = await ldClient.getContext()

        if (context && !context.anonymous) {
          setIsIdentified(true)
        }
      }
    },
    // Stop polling when identified
    isIdentified ? undefined : CONTEXT_CHECK_INTERVAL
  )

  return isIdentified
}

export default useLDUserContextIdentified
