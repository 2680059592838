// libraries
import _ from 'lodash'
import { ReactElement, ReactNode, useMemo } from 'react'
import styled from '@emotion/styled/macro'

// constants
import { PAGE_HEADER_TAB_POSITION } from 'constants/common'

// components
import { Tabs, TruncatingTextWithTooltip } from 'components/common'

import type { PageHeaderTabPosition } from 'types/common'
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import type { TabsProps } from '../Tabs'

import scss from './index.module.scss'

export type PageHeaderTabsProps = TabsProps & {
  tabsPosition: PageHeaderTabPosition
}

const StyledContainer = styled.div<{
  withLeftTabs: boolean
}>`
  ${({ withLeftTabs }) => (withLeftTabs ? `padding-bottom: 0px;` : '')}
`

export type PageHeaderProps = {
  iconName?: ReactNode
  iconComponent?: ReactNode
  titleComponent: ReactNode
  subtitleComponent?: ReactNode
  rightComponent?: ReactNode
  titleAddonComponent?: ReactNode
  className?: string
  children?: ReactNode
  contentComponent?: ReactNode
  toolbarComponent?: ReactNode
  tabsProps?: PageHeaderTabsProps
  titleFullWidth?: boolean
}

const PageHeader = ({
  iconName,
  className,
  children,
  iconComponent,
  titleComponent,
  titleAddonComponent,
  subtitleComponent,
  rightComponent,
  contentComponent = null,
  toolbarComponent,
  tabsProps,
  titleFullWidth,
}: PageHeaderProps): ReactElement => {
  const { tabsPosition, tabs } = tabsProps || {}

  const withLeftTabs = useMemo(
    () => !_.isEmpty(tabs) && tabsPosition === PAGE_HEADER_TAB_POSITION.left,
    [tabs, tabsPosition]
  )

  const withCenterTabs = useMemo(
    () => !_.isEmpty(tabs) && tabsPosition === PAGE_HEADER_TAB_POSITION.middle,
    [tabs, tabsPosition]
  )

  return (
    <header className='sticky-top flex-md-nowrap'>
      {titleComponent && (
        <StyledContainer
          className={`d-flex align-items-center justify-content-between ${scss.container} ${className}`}
          withLeftTabs={withLeftTabs}
        >
          <div className='flex-grow-1'>
            <div className='d-flex align-items-center'>
              {iconComponent}
              <div className='flex-grow-1'>
                <div className='d-flex flex-grow-1 align-items-center'>
                  <h1
                    className={`${scss.title} ${
                      titleFullWidth ? 'w-100' : ''
                    } ${withCenterTabs ? scss.truncateTitle : ''}`}
                  >
                    <TruncatingTextWithTooltip
                      placement={TOOLTIP_PLACEMENT.bottom}
                      title={titleComponent}
                    >
                      <>
                        {iconName}
                        {titleComponent}
                      </>
                    </TruncatingTextWithTooltip>
                  </h1>
                  {titleAddonComponent}
                </div>
                {withLeftTabs ? (
                  <Tabs {...tabsProps} />
                ) : (
                  <>{subtitleComponent}</>
                )}
              </div>
            </div>
          </div>
          {!_.isEmpty(tabs) &&
            tabsPosition === PAGE_HEADER_TAB_POSITION.middle && (
              <Tabs className={scss.center} {...tabsProps} />
            )}
          <div className='d-flex align-items-center'>
            {children}
            {rightComponent}
          </div>
        </StyledContainer>
      )}
      {contentComponent}
      {toolbarComponent && (
        <div className={scss.toolbar}>{toolbarComponent}</div>
      )}
    </header>
  )
}

export default PageHeader
