import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'

import { useConfigStateValue } from 'contexts'

import type { FeatureFlag, GemsConfiguration } from 'types/common'
import { FEATURES } from 'constants/settings'

export const initialEnabledFeatureFlagState = {
  [FEATURES.WORKFLOW]: true,
  [FEATURES.TILE_LAYER]: false,
  [FEATURES.ISSUE]: true,
  [FEATURES.SITE]: false,
  [FEATURES.ASSET]: true,
  [FEATURES.MAPS]: true,
  [FEATURES.WORKFLOW_GEOFENCE_ANCILLARY_DATA]: false,
  [FEATURES.DYNAMIC_SCHEDULING]: false,
  [FEATURES.JSON_FORM]: true,
  [FEATURES.CREATE_USER]: true,
  [FEATURES.LDAR_SIM_FILE_UPLOADER]: false,
  [FEATURES.JSON_FORM_LIST_BUILDER]: false,
  [FEATURES.DISPLAY_VENTING_EVENT_LOCATION_ON_MAP_IN_GEMS]: false,
  [FEATURES.DISPLAY_QUBE_ALARM_LOCATION_ON_MAP_IN_GEMS]: false,
  [FEATURES.CREATE_AN_ISSUE_FROM_AN_ACTION_ON_A_DETECTION_IN_AN_INBOX]: true,
  [FEATURES.LOGIN_REDIRECT_URL]: '/',
  [FEATURES.LOGIN_REDIRECT_METHANE]: false,
  [FEATURES.ENABLE_GROSS_EMISSIONS_DASHBOARD]: true,
}

const useFeatureFlag = (
  initEnableFlags: Partial<FeatureFlag> = initialEnabledFeatureFlagState
): FeatureFlag & { [FEATURES.GEMS_CONFIGURATION]?: GemsConfiguration } => {
  const { featureFlags } = useConfigStateValue()

  const launchDarklyFlags = useFlags()

  return useMemo(() => {
    return {
      ...initEnableFlags,
      ...featureFlags,
      ...launchDarklyFlags,
    }
  }, [initEnableFlags, featureFlags, launchDarklyFlags])
}

export default useFeatureFlag
