// constants
import {
  ENTITIES,
  PROPERTY_TIME,
  GALLERY_LIST_TYPES,
  TABLE_COLUMN_SUPPORT_GROUP_BY_KEY,
  GALLERY_LIST_FILTER_TYPES,
} from 'constants/common'
import { CUSTOMIZED_FILTERS_KEYS } from 'constants/issue'
import {
  DATE_RANGE_FILTER_OPTIONS,
  DATE_RANGE_FILTER_VALUES,
} from 'constants/filter'

// utils
import { getSortOptionsBasedOnColumns } from 'helpers/utils'

import type { DateFilterOptions } from 'types/filter'
import { isValidDateFilterValue } from 'helpers/filter'

import {
  DateTemplate,
  GroupTemplate,
  TextTemplate,
} from 'components/common/DataTable/CellTemplates'
import {
  ColumnsWithGroupable,
  getTableGroupableColumns,
} from 'components/common/DataTable/useDataTableColumns'
import {
  AssigneeTemplate,
  SeverityPickerTemplate,
  WorkflowTemplate,
  AnnotationsTemplate,
  FormTitleTemplate,
} from './CellTemplates'

export const GROUP_BY_FORM_FIELD = 'stateParameter.dataCollectionFormReference'

export const ISSUE_SUBTASK_TABLE_COLUMNS: ColumnsWithGroupable = [
  {
    header: 'Title',
    field: 'properties.title',
  },
  {
    header: 'Asset',
    field: 'properties.asset',
  },
  {
    header: 'Status',
    field: 'properties.statusLabel',
  },
  {
    header: 'Last updated',
    body: DateTemplate,
    field: 'properties.time',
  },
  {
    header: 'Form',
    body: FormTitleTemplate,
    // This columns is required just to display the 'body' on grouping
    hidden: true,
    field: GROUP_BY_FORM_FIELD,
  },
]

export const ISSUE_TABLE_COLUMNS: ColumnsWithGroupable = [
  {
    header: 'Asset',
    field: 'properties.asset',
    [TABLE_COLUMN_SUPPORT_GROUP_BY_KEY]: true,
  },
  {
    header: 'Title',
    field: 'properties.title',
  },
  {
    header: 'Description',
    body: TextTemplate,
    field: 'properties.description',
  },
  {
    header: 'Status',
    field: 'properties.statusLabel',
    [TABLE_COLUMN_SUPPORT_GROUP_BY_KEY]: true,
  },
  {
    header: 'Due',
    body: DateTemplate,
    field: 'properties.dueAt',
  },
  {
    header: 'Last updated',
    body: DateTemplate,
    field: 'properties.time',
  },
  {
    header: 'Comments',
    body: AnnotationsTemplate,
    field: 'annotations',
  },
  {
    header: 'Group',
    body: GroupTemplate,
    field: 'properties.owner.group',
  },
  {
    header: 'Workflow',
    body: WorkflowTemplate,
    field: 'properties.workflowName',
    [TABLE_COLUMN_SUPPORT_GROUP_BY_KEY]: true,
  },
  {
    header: 'Assignee',
    body: AssigneeTemplate,
    field: 'assigneeOption.value',
    [TABLE_COLUMN_SUPPORT_GROUP_BY_KEY]: true,
  },
  {
    header: 'Severity',
    body: SeverityPickerTemplate,
    field: 'severityOption.value',
    [TABLE_COLUMN_SUPPORT_GROUP_BY_KEY]: true,
  },
  {
    header: 'Form',
    body: FormTitleTemplate,
    // This column will be hidden from the table and the "column toggle" list,
    // but we still need to keep it to properly display the data on grouping.
    hidden: true,
    field: GROUP_BY_FORM_FIELD,
    [TABLE_COLUMN_SUPPORT_GROUP_BY_KEY]: true,
  },
]

export const FORM_RESPONSE_PROPERTIES_FIELD_KEY = 'formResponseProperties'

const sortOptions = getSortOptionsBasedOnColumns(ISSUE_TABLE_COLUMNS)

const enableListTypes = [GALLERY_LIST_TYPES.table, GALLERY_LIST_TYPES.map]

export const PAGE_OPTIONS = {
  iconName: 'IssuesIcon',
  entity: ENTITIES.issue,
  pageName: 'IssueGallery',
  listConditions: {
    listType: enableListTypes[0],
    sortField: PROPERTY_TIME,
    ascOrder: false,
    enableBulkEdit: true,
    enableActions: false,
  },
  toolboxOptions: {
    sortOptions,
    enableListTypes,
    searchBarPlaceholder: 'Search title or asset',
    tableGroupableColumns: getTableGroupableColumns(ISSUE_TABLE_COLUMNS),
    enableDateFilter: true,
    dateFilterOptions: {
      // 3 months
      maxDaysRange: 93,
      getDateRangeFromFilter: filterValues => {
        const filters = filterValues || {}
        const [startDate, endDate] =
          (filters[GALLERY_LIST_FILTER_TYPES.modifiedDatetime] as string[]) ||
          []

        return {
          startDate: isValidDateFilterValue(startDate)
            ? startDate
            : DATE_RANGE_FILTER_VALUES.today,
          endDate,
        }
      },
      transformSelectedRangeForFilter: value => ({
        [GALLERY_LIST_FILTER_TYPES.modifiedDatetime]: value,
      }),
      customizedDateRangeFilterOptions: [
        ...DATE_RANGE_FILTER_OPTIONS,
        {
          value: DATE_RANGE_FILTER_VALUES.this_quarter,
          label: 'This Quarter',
        },
      ],
    } as DateFilterOptions,
  },
  customizedFiltersKeys: CUSTOMIZED_FILTERS_KEYS,
  fetchEventsInterval: 5,
  fetchListOptions: {
    first: 250,
  },
}
