import { PropsWithChildren, ReactElement } from 'react'
import { TopBarLogo } from 'components/logos/TopBarLogo'
import scss from './index.module.scss'

const MainContentLayout = ({
  background = true,
  children,
}: PropsWithChildren<{
  background: boolean
}>): ReactElement => {
  return (
    <main
      role='main'
      className={`${scss.content} ${background && scss.background}`}
      id='main'
    >
      <TopBarLogo />
      {children}
    </main>
  )
}

export default MainContentLayout
