import _ from 'lodash'
import { ENTITIES } from 'constants/common'
import { DETECTION_LIST_FILTER_TYPES } from 'app/MissionControlMethaneSolution/constants/detection'

import { getQueryFields, getVariableArgs } from 'helpers/graphql'
import {
  getEntitiesQuery,
  getEntityGraphql,
  getEntityQuery,
  listRelayStyleData,
} from 'services/api/utils'

import type { Payload } from 'types/common'
import type { CamAsset } from 'app/MissionControlMethaneSolution/types/graphql'
import type { Fields } from 'types/services'
import type { AssetTypeId } from 'app/MissionControlMethaneSolution/constants/asset'
import { deserializeSite } from './detection'

const domain = ENTITIES.assetType
const queryDomain = `${domain}s`

const geAssetAllQuery = getEntitiesQuery<CamAsset>({
  resolverVariables: {
    first: '',
    after: '',
    filter: '',
    fullTextSearch: '',
  },
  getFieldsFn: getQueryFields({
    assetReference: true,
    geometryJson: true,
    group: true,
    properties: {
      ...getVariableArgs({ properties: null }),
    },
  }),
})

const getAssetTypeByIdQuery = (assetsOmitFields?: Fields) =>
  getEntityQuery({
    queryDomain,
    variables: {
      assetTypeId: 'ID!',
      first: 'Int',
      after: 'String',
      filter: 'CamAssetFilter',
      fullTextSearch: 'CamAssetFullTextSearch',
      properties: '[ID!]',
    },
    resolverVariables: {
      assetTypeId: 'ID!',
    },
    getFieldsFn: getQueryFields({
      assetTypeId: true,
      assets: geAssetAllQuery({ omitFields: assetsOmitFields }),
    }),
  })

const getAssetAllById = ({
  queryParams,
  assetsOmitFields,
}: {
  queryParams: Payload
  assetsOmitFields?: Fields
}) =>
  listRelayStyleData<CamAsset>({
    queryDomain,
    fnName: [queryDomain, 'byId', 'assets', 'all'].join('.'),
    queryParams,
    getQueryFn: getAssetTypeByIdQuery(assetsOmitFields),
    queryDisplayName: 'GetAssetTypeById',
    enableLoadMore: true,
  })

export const getAssetsByType =
  (assetTypeId: AssetTypeId) =>
  async ({
    properties,
    queryParams,
    group,
    assetsOmitFields = ['geometryJson'],
  }: {
    queryParams?: { filter?: Payload }
    assetsOmitFields?: Fields
    group?: string
    properties?: Fields
  } & Payload) => {
    const { filter } = queryParams ?? {}

    const validFilters = _.map(filter, (valueJson, property) => {
      return {
        property:
          property === DETECTION_LIST_FILTER_TYPES.dacSite ? 'isDAC' : property,
        valueJson,
      }
    })

    const hasValidFilters = !_.isEmpty(validFilters)

    return getAssetAllById({
      queryParams: {
        assetTypeId: _.lowerCase(assetTypeId),
        ...((hasValidFilters || group) && {
          filter: {
            ...(hasValidFilters && { propertyFilters: validFilters }),
            ...(group && { group: [group] }),
          },
        }),
        properties,
        first: 20000,
      },
      assetsOmitFields,
    })
  }

const geAssetQuery = getEntityQuery({
  identifier: null,
  resolverVariables: {
    id: 'ID!',
  },
  getFieldsFn: getQueryFields({
    assetReference: true,
    geometryJson: true,
    properties: {
      ...getVariableArgs({ properties: null }),
    },
  }),
})

const getAssetByIdQuery = getEntityQuery({
  queryDomain,
  variables: {
    assetTypeId: 'ID!',
    id: 'ID!',
    properties: '[ID!]',
  },
  resolverVariables: {
    assetTypeId: 'ID!',
  },
  getFieldsFn: getQueryFields({
    assetTypeId: true,
    assets: geAssetQuery({}),
  }),
})

export const getAssetById = getEntityGraphql<CamAsset>({
  queryDomain,
  queryName: null,
  getQueryFn: getAssetByIdQuery,
  queryDisplayName: 'GetAssetById',
  postProcessFn: d => {
    return deserializeSite(_.get(d, 'byId.assets.byId'))
  },
})
