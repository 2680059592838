import { ReactElement, useMemo, useCallback, PropsWithChildren } from 'react'

// types
import type { UtcISOString } from 'types/datetime'
import type { Option } from 'types/common'

// constants
import { DATE_UNIT_TYPES } from 'constants/datetime'

// components
import { MultiSelect } from 'components/common'
import { DateRangePicker } from 'components/common/DateTime'

// helpers
import { getFutureUtcDate, isValidISODatetimeString } from 'helpers/datetime'
import { useTimezone } from 'hooks'

import scss from './index.module.scss'
import { FilterCustomOption } from '../FilterDropdown/components'

const Control = () => <></>

type DateTimeFilterValue = [string?, string?]

export type DateTimeFilterOption = Option & { hasEndDate?: boolean }

export type DateTimeFilterBodyProps = PropsWithChildren<{
  value?: DateTimeFilterValue
  onTimeChange: (newTimeValue: DateTimeFilterValue) => void
  onDatePickerClickAway?: () => void
  formatOptionLabel?: (v: unknown) => string
  options: DateTimeFilterOption[]
  maxDaysRange?: number
}>

const DateTimeFilterBody = ({
  value,
  onTimeChange,
  onDatePickerClickAway,
  formatOptionLabel,
  options,
  maxDaysRange = 31,
  children,
}: DateTimeFilterBodyProps): ReactElement => {
  const { timezone } = useTimezone()

  const [startTime, endTime] = value || []

  const isTimeRelative = useMemo(
    () => !isValidISODatetimeString(startTime),
    [startTime]
  )

  const getMaxDate = useCallback(
    (minDate: UtcISOString) => {
      if (
        maxDaysRange === Infinity ||
        // This check ensures we don't block a user from selecting a new date range starting from the future date
        (startTime && endTime)
      ) {
        return undefined
      }

      return getFutureUtcDate({
        interval: maxDaysRange,
        intervalUnit: DATE_UNIT_TYPES.days,
        baseDatetime: minDate,
      })?.toISOString()
    },
    [startTime, endTime, maxDaysRange]
  )

  return (
    <>
      <DateRangePicker
        startDate={isTimeRelative ? undefined : startTime}
        endDate={isTimeRelative ? undefined : endTime}
        onChange={v => {
          const { startDate, endDate } = v || {}
          const newTimeValue = [startDate, endDate]
          onTimeChange(newTimeValue as DateTimeFilterValue)
        }}
        onClickAway={onDatePickerClickAway}
        timezone={timezone}
        className={scss.timePicker}
        getMaxDate={getMaxDate}
        inputText='Custom Range'
      >
        {children}
      </DateRangePicker>
      <MultiSelect
        autoFocus
        isSearchable={false}
        components={{
          Option: FilterCustomOption(formatOptionLabel),
          Control,
        }}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        menuIsOpen
        isClearable={false}
        onChange={({ value: newValue, hasEndDate }: DateTimeFilterOption) => {
          onTimeChange([
            newValue,
            hasEndDate ? newValue : undefined,
          ] as DateTimeFilterValue)
        }}
        options={options}
        tabSelectsValue={false}
        value={isTimeRelative ? startTime : undefined}
        isMulti={false}
        zIndex={1}
      />
    </>
  )
}

export default DateTimeFilterBody
