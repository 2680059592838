import { useMemo } from 'react'
import _ from 'lodash'

// constants
import { APP_NAMES } from 'constants/common'
import { FEATURES, SETTINGS_TYPES } from 'constants/settings'

// utils
import { useAuthCurrentUser } from 'contexts/hooks'

// components
import SecurityAndPolicy from 'components/settings/SecurityAndPolicy'
import UserProfile from 'components/settings/UserProfile'
import Versions from 'components/settings/Versions'
import FormGallery from 'routers/pages/FormGallery'

const useSettingsMenu = () => {
  const { appName } = useAuthCurrentUser()

  const settingsMenu = useMemo(
    () => [
      {
        label: 'MY SETTINGS',
        group: [
          {
            label: 'My profile',
            value: SETTINGS_TYPES.userProfile,
            icon: 'FaRegUserCircle',
            component: UserProfile,
          },
          {
            label: 'Security and Privacy',
            value: SETTINGS_TYPES.securityAndPrivacy,
            icon: 'MdSecurity',
            component: SecurityAndPolicy,
          },
          {
            label: 'Versions',
            value: SETTINGS_TYPES.versions,
            component: Versions,
          },
        ],
      },
      ...(appName !== APP_NAMES.methane
        ? [
            {
              label: 'APP CONFIGURATION',
              group: [
                {
                  label: 'Form designer',
                  value: SETTINGS_TYPES.formDesigner,
                  icon: 'FormBuilderIcon',
                  component: FormGallery,
                  isExpanded: true,
                  enabled: FEATURES.JSON_FORM,
                  sidebarRouteEnabled: true,
                },
              ],
            },
          ]
        : []),
    ],
    [appName]
  )

  const settingsMenuWithoutGroup = useMemo(
    () => _.flatMap(settingsMenu, 'group'),
    [settingsMenu]
  )
  const settingsMenuKeyByValue = useMemo(
    () => _.keyBy(settingsMenuWithoutGroup, 'value'),
    [settingsMenuWithoutGroup]
  )

  return {
    settingsMenu,
    settingsMenuWithoutGroup,
    settingsMenuKeyByValue,
  }
}

export default useSettingsMenu
