import { useMemo, useState, Dispatch } from 'react'
import _ from 'lodash'

// constants
import { GALLERY_LIST_FILTER_TYPES } from 'constants/common'

// utils
import { isUser } from 'helpers/user'
import { useAuthStateValue } from 'contexts'

import type { User } from 'types/user'
import type { FilterSpec, Filters } from 'types/filter'
import { isValidDateFilterValue } from 'helpers/filter'
import { DATE_RANGE_FILTER_VALUES } from 'constants/filter'

type Keys = string[]

type UseListFilterProps = {
  filters: Filters
  defaultFilters?: Filters
  customizedFiltersKeys?: Keys
}

type UseGalleryListFilterProps = {
  entity: string
  customFiltersSpecs?: FilterSpec[]
  customizedFiltersKeys?: Keys
  customizedDefaultFilters?: Filters
}

type PickValidFiltersProps = {
  filters: Filters | undefined
  keys: Keys | undefined
  defaultFilters?: Filters
}

type UseListFilterReturn = {
  filterValues: Filters
  setFilterValues: Dispatch<Filters>
}

type UseGalleryListFilterReturn = UseListFilterReturn

export const pickValidFilters = ({
  filters,
  keys,
  defaultFilters = {},
}: PickValidFiltersProps): Filters => {
  if (filters && _.isEmpty(filters)) return {}

  if (keys) {
    return _.isEmpty(keys)
      ? {}
      : _.pick(_.defaults({}, filters, defaultFilters), keys)
  }

  return filters ?? defaultFilters
}

const useListFilter = ({
  filters,
  defaultFilters = {},
  customizedFiltersKeys,
}: UseListFilterProps): UseListFilterReturn => {
  const [filterValues, setFilterValues] = useState<Filters>(() =>
    pickValidFilters({
      filters,
      defaultFilters,
      keys: customizedFiltersKeys,
    })
  )

  return { filterValues, setFilterValues }
}

const getValidDateFilterValue = (value: string): string => {
  return isValidDateFilterValue(value) ? value : DATE_RANGE_FILTER_VALUES.today
}

export const useGalleryListFilter = ({
  entity,
  customFiltersSpecs,
  customizedFiltersKeys,
  customizedDefaultFilters,
}: UseGalleryListFilterProps): UseGalleryListFilterReturn => {
  const { currentUser } = useAuthStateValue()

  const { group: currentUserGroup, role, preferences }: User = currentUser

  const defaultFilters = useMemo(() => {
    return {
      ...customizedDefaultFilters,
      ...(!isUser(role) && {
        [GALLERY_LIST_FILTER_TYPES.group]: [currentUserGroup],
        [GALLERY_LIST_FILTER_TYPES.isPrivate]: [false],
      }),
    }
  }, [currentUserGroup, role, customizedDefaultFilters])

  const validPreferenceEntityFilters = useMemo(() => {
    const entityFilters = preferences?.filters?.[entity]
    const { startDate, endDate } = entityFilters || {}
    return {
      ...entityFilters,
      ...(startDate && {
        startDate: getValidDateFilterValue(startDate as string),
      }),
      ...(endDate && {
        endDate: getValidDateFilterValue(endDate as string),
      }),
    }
  }, [entity, preferences])

  const { filterValues, setFilterValues } = useListFilter({
    defaultFilters,
    filters: validPreferenceEntityFilters,
  })

  const filters = useMemo(() => {
    if (_.isEmpty(customFiltersSpecs)) {
      return pickValidFilters({
        filters: filterValues,
        keys: customizedFiltersKeys,
      })
    }

    return _.reduce(
      customFiltersSpecs,
      (acc, specs) => {
        const { filterKey, key } = specs
        const filterValue = filterValues[filterKey ?? key] ?? filterValues[key]
        return _.isNil(filterValue) ? acc : { ...acc, [key]: filterValue }
      },
      {}
    )
  }, [customFiltersSpecs, customizedFiltersKeys, filterValues])

  return {
    setFilterValues,
    filterValues: filters,
  }
}

export default useListFilter
