import { WatchQueryFetchPolicy, FetchPolicy } from '@apollo/client'

type Replace<
  T extends string,
  S extends string,
  D extends string,
  A extends string = ''
> = T extends `${infer L}${S}${infer R}`
  ? Replace<R, S, D, `${A}${L}${D}`>
  : `${A}${T}`

export const APOLLO_FETCH_POLICY: Record<
  Uppercase<Replace<WatchQueryFetchPolicy | FetchPolicy, '-', '_'>>,
  WatchQueryFetchPolicy | FetchPolicy
> = {
  CACHE_FIRST: 'cache-first',
  CACHE_AND_NETWORK: 'cache-and-network',
  NETWORK_ONLY: 'network-only',
  CACHE_ONLY: 'cache-only',
  NO_CACHE: 'no-cache',
  STANDBY: 'standby',
}
