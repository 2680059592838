// libraries
import _ from 'lodash'
import { useMemo, ReactElement, lazy, Suspense, useEffect, FC } from 'react'
import { Helmet } from 'react-helmet'
import * as Sentry from '@sentry/react'
import { RecoilRoot } from 'recoil'
import { ThemeProvider as EmotionThemeProvider, Theme } from '@emotion/react'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { withLDProvider, useLDClient } from 'launchdarkly-react-client-sdk'
import { ApolloProvider } from '@apollo/client'
import { useMount } from 'react-use'
import { LicenseInfo } from '@mui/x-license'

// constants
import { APP_NAMES } from 'constants/common'

// utils
import {
  StateProvider,
  ConfigProvider,
  AbilityProvider,
  AuthenticationProvider,
  useAuthStateValue,
  StaticResourceProvider,
  useConfigStateValue,
  HeapProvider,
} from 'contexts'
import { getBrandingColours } from 'helpers/colour'
import { useBranding, useCurrentUser, useMuiTheme } from 'hooks'
import { lazyWithRetry } from 'routers/utils'
import { useInitApolloClient } from 'apollo/hooks'
import log from 'helpers/log'

// components
import { AppError, Loading } from 'components/common'
import HotToaster from 'components/common/Toast/HotToaster'
import { SensorUpLogo } from 'components/logos/SensorUpLogo'

// styles
import 'mapbox-gl/dist/mapbox-gl.css'
import variables from 'SUExplorer.module.scss'

const MissionControlStudio = lazy(() =>
  lazyWithRetry(() => import('app/MissionControlStudio'))
)

const MissionControlMethaneSolution = lazy(() =>
  lazyWithRetry(() => import('app/MissionControlMethaneSolution'))
)

type BrandingTheme = Theme & {
  'primary-100': string
  'primary-500': string
  'secondary-light-500': string
  'secondary-light-600': string
}

const SUExplorer: FC = () => {
  const { appName } = useAuthStateValue()
  const ldClient = useLDClient()
  const { currentUserInfo } = useCurrentUser()

  useEffect(() => {
    const updateLaunchDarklyUser = () => {
      if (ldClient) {
        ldClient.identify({
          key: 'user',
          kind: 'user',
          ...currentUserInfo,
        })
      }
    }

    updateLaunchDarklyUser()
  }, [ldClient, currentUserInfo])

  useEffect(() => {
    if (!currentUserInfo.id) return

    if (window.userGuiding) {
      window.userGuiding.identify(currentUserInfo.id, currentUserInfo)
      log.info('Update UserGuiding user info')
    }
  }, [currentUserInfo])

  const App = useMemo(
    () =>
      appName === APP_NAMES.methane
        ? MissionControlMethaneSolution
        : MissionControlStudio,
    [appName]
  )

  return (
    <main>
      <Suspense fallback={<Loading />}>
        <App />
        <HotToaster />
        <SensorUpLogo />
      </Suspense>
    </main>
  )
}

const ThemedSuExplorer = (): ReactElement => {
  const { title, colour = {} } = useBranding()

  const adjustedColour = useMemo(() => {
    const { primary } = colour || {}

    return {
      ...colour,
      ...getBrandingColours(primary),
      ..._.omit(variables, 'app'),
    } as BrandingTheme
  }, [colour])

  const muiTheme = useMuiTheme({ theme: adjustedColour })

  const themeVariables = `
    :root {
      --primary-100: ${adjustedColour['primary-100']};
      --primary-500: ${adjustedColour['primary-500']};
      --secondary-light-500: ${adjustedColour['secondary-light-500']};
      --secondary-light-600: ${adjustedColour['secondary-light-600']};
    }
  `

  return (
    <EmotionThemeProvider theme={adjustedColour}>
      <MuiThemeProvider theme={muiTheme}>
        <AuthenticationProvider>
          <>
            <Helmet>
              <title>{title || 'SensorUp Mission Control'}</title>
              <style>{themeVariables}</style>
            </Helmet>
            <HeapProvider heapAppId='1354571557'>
              <SUExplorer />
            </HeapProvider>
          </>
        </AuthenticationProvider>
      </MuiThemeProvider>
    </EmotionThemeProvider>
  )
}

const ContextualizedSuExplorer = (): ReactElement => {
  const { clientName, clientVersion } = useConfigStateValue()
  const apolloClient = useInitApolloClient({
    clientName,
    clientVersion,
  })
  return (
    <ApolloProvider client={apolloClient}>
      <StaticResourceProvider>
        <StateProvider>
          <AbilityProvider>
            <ThemedSuExplorer />
          </AbilityProvider>
        </StateProvider>
      </StaticResourceProvider>
    </ApolloProvider>
  )
}

const FeatureFlaggedSuExplorer = (): ReactElement => {
  const { launchDarkly } = useConfigStateValue()

  const launchDarklyClientId = useMemo(() => {
    return _.get(launchDarkly, 'client_id')
  }, [launchDarkly])

  const App = useMemo(() => {
    const ldConfig = {
      clientSideID: launchDarklyClientId as string,
    }
    return withLDProvider(ldConfig)(ContextualizedSuExplorer)
  }, [launchDarklyClientId])

  return <App />
}

const SUExplorerContainer = (): ReactElement => {
  useMount(() => {
    const setupMuiLicenseKey = () => {
      const muiLicenseKey = process.env.REACT_APP_MUI_LICENSE_KEY
      if (muiLicenseKey) {
        LicenseInfo.setLicenseKey(muiLicenseKey)
      }
    }

    setupMuiLicenseKey()
  })

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => <AppError message={error.toString()} />}
    >
      <RecoilRoot>
        <ConfigProvider>
          <FeatureFlaggedSuExplorer />
        </ConfigProvider>
      </RecoilRoot>
    </Sentry.ErrorBoundary>
  )
}

export default SUExplorerContainer
