import _ from 'lodash'
import { CSSProperties, useCallback, useMemo } from 'react'
import {
  DataGridPremium,
  GridRow,
  type GridRowModel,
  type GridRowParams,
  type GridRowProps,
  type DataGridPremiumProps,
  type GridRowIdGetter,
} from '@mui/x-data-grid-premium'
import * as Sentry from '@sentry/react'
import { getObjectHash } from 'helpers/utils'
import type { TreeDataGridProps, TreeTableActions } from './types'
import { loadingMoreOverlay, loadingOverlay } from '../DataGrid'

import scss from './index.module.scss'
import { SORTING_ORDER } from '../DataGrid/constants'

const buildTreePath = <T extends GridRowModel>(row: T, data: T[]): string[] => {
  if (!row) return []

  const parent = _.find(data, { id: row.parentId })
  return parent
    ? [...buildTreePath(parent, data), row.id as string]
    : [row.id as string]
}

const COLUMN_HEADER_HEIGHT = 42
const DEFAULT_ROW_HEIGHT = 44

const CustomRow =
  (itemActions: TreeTableActions) =>
  ({ row, ...props }: GridRowProps) =>
    (
      <GridRow
        {...props}
        row={row}
        onMouseEnter={() => itemActions.onMouseEnter?.(row)}
        onMouseLeave={() => itemActions.onMouseLeave?.(row)}
      />
    )

const TreeDataGrid = <T extends GridRowModel>({
  itemActions = {},
  sortField,
  sortOrder,
  currentActiveItem,
  list = [],
  dataKey = 'id',
  slots: slotsProp,
  slotProps: slotPropsProp,
  style,
  loading,
  loadingMore,
  noMaxHeight = false,
  initialState: initialStateProp,
  localeText: localeTextProp,
  isTree = true,
  ...restProps
}: TreeDataGridProps<T> & {
  style?: CSSProperties
  noMaxHeight?: boolean
}): JSX.Element => {
  const getRowClassName = useCallback(
    (params: GridRowParams<T>): string => {
      if (params.id === currentActiveItem?.id) return 'Mui-selected'
      return params.row.parentId ? 'tree-child-row' : 'tree-parent-row'
    },
    [currentActiveItem]
  )

  const memoizedBuildTreePath = useCallback(
    (row: T) => buildTreePath(row, list),
    [list]
  )

  const hideFooter = useMemo(() => !_.has(slotsProp, 'footer'), [slotsProp])

  const initialState = useMemo<DataGridPremiumProps['initialState']>(
    () => ({
      ...(sortField && {
        sorting: {
          sortModel: [{ field: sortField, sort: sortOrder ? 'asc' : 'desc' }],
        },
      }),
      ...(initialStateProp || {}),
    }),
    [sortField, sortOrder, initialStateProp]
  )

  const slots = useMemo<DataGridPremiumProps['slots']>(
    () => ({
      row: CustomRow(itemActions),
      ...(slotsProp || {}),
    }),
    [slotsProp, itemActions]
  )

  const slotsProps = useMemo<DataGridPremiumProps['slotProps']>(
    () => ({
      loadingOverlay: loadingMore ? loadingMoreOverlay : loadingOverlay,
      ...(slotPropsProp || {}),
    }),
    [slotPropsProp, loadingMore]
  )

  const localeText = useMemo<DataGridPremiumProps['localeText']>(
    () => ({ noRowsLabel: 'No items', ...(localeTextProp || {}) }),
    [localeTextProp]
  )

  const rowSelectionModel = useMemo(
    () => (currentActiveItem?.[dataKey] ? [currentActiveItem?.[dataKey]] : []),
    [currentActiveItem, dataKey]
  )

  const getRowId = useCallback<GridRowIdGetter>(
    row => row[dataKey] ?? getObjectHash(row),
    [dataKey]
  )

  const containerStyle = useMemo(() => {
    const defaultStyles = style || {}
    return noMaxHeight ? defaultStyles : { height: '100%', ...defaultStyles }
  }, [noMaxHeight, style])

  return (
    <div className={scss.container} style={containerStyle}>
      <DataGridPremium
        {...(isTree ? { treeData: isTree } : {})}
        rows={list}
        columnHeaderHeight={COLUMN_HEADER_HEIGHT}
        rowHeight={DEFAULT_ROW_HEIGHT}
        sortingOrder={SORTING_ORDER}
        getRowClassName={getRowClassName}
        initialState={initialState}
        slots={slots}
        slotProps={slotsProps}
        localeText={localeText}
        rowSelectionModel={rowSelectionModel}
        getRowId={getRowId}
        hideFooter={hideFooter}
        getTreeDataPath={memoizedBuildTreePath}
        loading={loading || loadingMore}
        disableRowSelectionOnClick
        {...restProps}
      />
    </div>
  )
}

export const TreeDataGridContainer = <T extends GridRowModel>(
  props: TreeDataGridProps<T>
) => {
  return (
    <Sentry.ErrorBoundary fallback={() => <>Something went wrong.</>}>
      <TreeDataGrid {...props} />
    </Sentry.ErrorBoundary>
  )
}

export default TreeDataGridContainer
