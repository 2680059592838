import {
  ReactElement,
  useState,
  useCallback,
  UIEvent,
  PropsWithChildren,
} from 'react'
import styled from '@emotion/styled'
import LinearProgress from '@mui/material/LinearProgress'

import scss from './index.module.scss'

const StyledLinearProgress = styled(LinearProgress)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--blade-linear-loader-z-index);
`

const BladeContent = ({
  displayLinearLoader,
  children,
}: PropsWithChildren<{ displayLinearLoader?: boolean }>): ReactElement => {
  const [displayTopFader, setDisplayTopFader] = useState(false)

  const onScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      // Display the top fader once user started to scroll
      if (!displayTopFader && e.currentTarget.scrollTop > 0)
        setDisplayTopFader(true)
      // Hide the top fader once the scroll is on very top position again
      if (displayTopFader && e.currentTarget.scrollTop === 0)
        setDisplayTopFader(false)
    },
    [displayTopFader, setDisplayTopFader]
  )

  return (
    <div className={scss.bladeContentWrapper}>
      {displayLinearLoader && <StyledLinearProgress />}
      {displayTopFader && (
        <div className={scss.topFader} data-testid='blade-top-fader' />
      )}
      <div
        className={scss.bladeContent}
        onScroll={onScroll}
        data-testid='blade-scrollable-content'
      >
        {children}
      </div>
    </div>
  )
}

export default BladeContent
