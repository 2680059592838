import { memo } from 'react'
import variables from 'SUExplorer.module.scss'

export const SensorUpLogo = memo(() => (
  <a
    href='https://sensorup.com'
    target='_blank'
    rel='noreferrer'
    className={variables.sensorUpLogo}
  >
    <img src='/assets/logo/SensorUp-Powered-by-Logo.svg' alt='SensorUp' />
  </a>
))

SensorUpLogo.displayName = 'SensorUpLogo'
